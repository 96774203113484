import React, {useEffect} from "react";
import "../about/about.css";
import jigar from "../about/aboutimg/Jigar Shah.webp";
import mansi from "../about/aboutimg/Mansi.webp";
import anupriya from "../about/aboutimg/Anupriya Saxena.webp";

import Picture1 from "../about/aboutimg/Picture1.webp";
import Picture2 from "../about/aboutimg/Picture2.webp";
import Picture3 from "../about/aboutimg/Picture3.webp";
import Picture4 from "../about/aboutimg/Picture4.webp";
import Picture5 from "../about/aboutimg/Picture5.webp";
import Picture6 from "../about/aboutimg/Picture6.webp";
import Picture7 from "../about/aboutimg/Picture7.webp";
import Picture8 from "../about/aboutimg/Picture8.webp";



const Team = () => {

    useEffect(() => { 
      document.title = "Meet the Team";
    
    }, []);
  return (
    <>
      <div id="team" className="mid">
        <div className="midcontent">
          <p>The names you know, the people you don't</p>
          <p>Our Team</p>
        </div>
      </div>

      <div className="founder">
        <div className="titlebox">
          <h1>The Brains Behind The Work</h1>
          <p>Leadership Team</p>
        </div>
        <div className="foundermembers">
          <div className="profile-card">
            <div className="founderimg">
              <img src={jigar} alt="" />
              <div className="caption">
                <h3>CS Jigar Shah</h3>
                <p>Founder Partner</p>
                <div className="foundersocial">
                  <a
                    href="https://www.linkedin.com/in/fcsjigarshah/"
                    target="_blanked"
                  >
                    <i className="fa-brands fa-linkedin"></i>
                  </a>
                  <a href="https://x.com/FCSJigarShah" target="_blanked">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      version="1.1"
                      id="Layer_1"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      style={{ enableBackground: "new 0 0 24 24" }}
                      xmlSpace="preserve"
                    >
                      <path d="M14.095479,10.316482L22.286354,1h-1.940718l-7.115352,8.087682L7.551414,1H1l8.589488,12.231093L1,23h1.940717  l7.509372-8.542861L16.448587,23H23L14.095479,10.316482z M11.436522,13.338465l-0.871624-1.218704l-6.924311-9.68815h2.981339  l5.58978,7.82155l0.867949,1.218704l7.26506,10.166271h-2.981339L11.436522,13.338465z" />
                    </svg>
                  </a>
                </div>
              </div>
              <div className="aboutfounder">
                <p>
                  CS Jigar Shah is a Fellow Member of the Institute of Company
                  Secretaries of India (ICSI) and a Law graduate from Mumbai
                  University. With nearly 2 decades of Industry experience, he
                  has worked with various Conglomerates and Industry leaders.
                  <br />
                  <br /> Renowned for his in-depth expertise in the Companies
                  Act, he is actively participating in knowledge sharing
                  activities as an Advisor, Panellist and a Speaker at various
                  organisation and forums on a wide array of topics, including
                  Corporate Laws, ESG, CSR, and Prohibition of Insider Trading,
                  Digital Branding for professionals and so on.
                </p>
              </div>
            </div>
          </div>

          <div className="profile-card">
            <div className="founderimg">
              <img src={mansi} alt="" />
              <div className="caption">
                <h3>CS Mansi Damania </h3>
                <p>Founder Partner</p>
                <div className="foundersocial">
                  <a
                    href="https://www.linkedin.com/in/csmansi/"
                    target="_blanked"
                  >
                    <i className="fa-brands fa-linkedin"></i>
                  </a>

                  <a href="https://x.com/mansidamania" target="_blanked">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      version="1.1"
                      id="Layer_1"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      style={{ enableBackground: "new 0 0 24 24" }}
                      xmlSpace="preserve"
                    >
                      <path d="M14.095479,10.316482L22.286354,1h-1.940718l-7.115352,8.087682L7.551414,1H1l8.589488,12.231093L1,23h1.940717  l7.509372-8.542861L16.448587,23H23L14.095479,10.316482z M11.436522,13.338465l-0.871624-1.218704l-6.924311-9.68815h2.981339  l5.58978,7.82155l0.867949,1.218704l7.26506,10.166271h-2.981339L11.436522,13.338465z" />
                    </svg>
                  </a>
                </div>
              </div>
              <div className="aboutfounder">
                <p>
                  CS Mansi Damania is a Fellow Member of the Institute of
                  Company Secretaries of India (ICSI) and a graduate of Mumbai
                  University Law School, has established a distinguished career
                  in Corporate Law. Since founding Mansi Damania and Associates
                  in 2008, she has been providing expert counsel and
                  comprehensive services to clients across a wide range of
                  corporate matters.
                  <br />
                  <br /> Her expertise extends to advising on complex
                  secretarial issues, conducting mandatory audits and due
                  diligence procedures, and navigating the intricate compliance
                  landscape of listed companies. She has managed various
                  projects such as Migration to the Main Board, Reclassification
                  of Promoters etc. along with various Secretarial work such as
                  Secretarial Audit, working as Scrutinizer for PSU Banks and
                  managing Due Diligence etc.
                </p>
              </div>
            </div>
          </div>

          <div className="profile-card">
            <div className="founderimg">
              <img src={anupriya} alt="" />
              <div className="caption">
                <h3>CS Anupriya Saxena</h3>
                <p>Managing Partner</p>
                <div className="foundersocial">
                  <a
                    href="https://www.linkedin.com/in/csanupriya/"
                    target="_blanked"
                  >
                    <i className="fa-brands fa-linkedin"></i>
                  </a>

                  <a href="https://x.com/Anupriyasays" target="_blanked">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      version="1.1"
                      id="Layer_1"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      style={{ enableBackground: "new 0 0 24 24" }}
                      xmlSpace="preserve"
                    >
                      <path d="M14.095479,10.316482L22.286354,1h-1.940718l-7.115352,8.087682L7.551414,1H1l8.589488,12.231093L1,23h1.940717  l7.509372-8.542861L16.448587,23H23L14.095479,10.316482z M11.436522,13.338465l-0.871624-1.218704l-6.924311-9.68815h2.981339  l5.58978,7.82155l0.867949,1.218704l7.26506,10.166271h-2.981339L11.436522,13.338465z" />
                    </svg>
                  </a>
                </div>
              </div>
              <div className="aboutfounder">
                <p>
                  CS Anupriya Saxena is a Fellow Member of the Institute of
                  Company Secretaries of India (ICSI). She is also holding an
                  executive degree from the prestigious Indian Institute of
                  Management Calcutta (IIM-C).
                  <br />
                  <br />
                  Her areas of specialization encompass a diverse range of
                  corporate transactions, including foreign and domestic equity
                  and debt funding, start-up advisory services, and related
                  transactional matters. In addition, she is a seasoned
                  professional in managing the compliance requirements of
                  Alternative Investment Funds (AIFs) and Portfolio management
                  Scheme (PMS). Her expertise extends to providing POSH
                  consultancy and compliance services, and she serves as a
                  valued external member on the POSH committees of various
                  companies.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="team">
        <div className="title-box">
          <h1>The minds at work</h1>
          <p> Core Team</p>
        </div>

        <div className="team-row">
          <div className="profile-box">
            <img src={Picture1} alt="" />
            <div className="profile-content">
              <h4>CS Raashi Singhi</h4>
              <small>Associate Partner</small>

              <a
                href="https://www.linkedin.com/in/csraashisinghi/"
                target="_blanked"
              >
                <i className="fa-brands fa-linkedin"></i>
              </a>
            </div>

            <div className="profile-box-desc">
              CS Raashi Singhi is a Fellow Member of the Institute of Company
              Secretaries of India (ICSI). She has been with JMJA for over 5
              years and have managed various assignments relating to listed
              companies such as migration of the Company from SME to main board,
              IPO preparation, post listing compliances and IEPF related
              compliances amongst other things.
            </div>
          </div>

          <div className="profile-box">
            <img src={Picture3} alt="CS Srushti Shah" />

            <div className="profile-content">
              <h4>CS Srushti Shah</h4>
              <small>General Manager</small>

              <a
                href="https://www.linkedin.com/in/cssrushtishah/"
                target="_blanked"
              >
                <i className="fa-brands fa-linkedin"></i>
              </a>
            </div>

            <div className="profile-box-desc">
              CS Srushti Shah is an Associate Member of the Institute of Company
              Secretaries of India (ICSI). With a distinguished five-year
              post-qualification career, she has cultivated a deep expertise in
              corporate compliance. Her practical experience encompasses a
              diverse range of regulatory facets, including company
              incorporation, private placements, startup and MSME regulations,
              CSR initiatives, and more. Beyond her compliance role, she also
              serves as a dedicated trainer, conducting POSH (Prevention of
              Sexual Harassment) sessions tailored to the specific needs of
              clients' internal committees and employees.
            </div>
          </div>

          <div className="profile-box">
            <img src={Picture2} alt="" />
            <div className="profile-content">
              <h4>CS Sudhamini Iyengar</h4>
              <small>Associate Consultant</small>
              <a
                href="https://www.linkedin.com/in/fcssaudhaminiiyengar/"
                target="_blanked"
              >
                <i className="fa-brands fa-linkedin"></i>
              </a>
            </div>

            <div className="profile-box-desc">
              CS Saudhamini Iyenger is a Fellow Member of the Institute of
              Company Secretaries of India (ICSI). With a comprehensive
              understanding of the Insolvency and Bankruptcy Code (IBC), she has
              recently expanded her expertise into the domain of POSH. As a POSH
              Consultant, she conducts informative orientation sessions for both
              employees and Internal Complaints Committees (ICCs). Her areas of
              specialization include Company Law, Voluntary Winding Up, Employee
              Stock Option Plan (ESOP) consultancy, drafting of legal
              agreements, and representing clients before the National Company
              Law Tribunal (NCLT)
            </div>
          </div>

          <div className="profile-box">
            <img src={Picture4} alt="" />
            <div className="profile-content">
              <h4>CS Rinku Kholakiya</h4>
              <small>Manager</small>
              <a
                href="https://www.linkedin.com/in/cs-rinku-kholakiya/"
                target="_blanked"
              >
                <i className="fa-brands fa-linkedin"></i>
              </a>
            </div>

            <div className="profile-box-desc">
              CS Rinku Kholakiya is an Associate Member of the Institute of
              Company Secretaries of India (ICSI). She has demonstrated a strong
              commitment to regulatory compliance and corporate governance. Her
              proficiency extends to handling statutory filings, corporate
              restructuring, and meticulously maintaining compliance records
            </div>
          </div>

          <div className="profile-box">
            <img src={Picture8} alt="" />
            <div className="profile-content">
              <h4>Mehjabeen</h4>
              <small style={{ color: "transparent" }}>----------</small>
              <a
                href="https://www.linkedin.com/in/mehjabeen-shaikh-572a10204"
                target="_blanked"
              >
                <i className="fa-brands fa-linkedin"></i>
              </a>
            </div>

            <div className="profile-box-desc">
              She has developed a profound understanding of corporate governance
              and compliance, with deep expertise in navigating the complexities
              of regulatory requirements and company law. Her extensive
              experience spans organizing and managing board and shareholder
              meetings, overseeing private placements, and meticulously
              maintaining statutory records to ensure that all corporate actions
              are in full compliance with legal and ethical standards.
            </div>
          </div>

          <div className="profile-box">
            <img src={Picture7} alt="" />
            <div className="profile-content">
              <h4>Mansi Vaje</h4>
              <small>Senior Associate</small>
              <a
                href="https://www.linkedin.com/in/mansi-vaje-551a56174/"
                target="_blanked"
              >
                <i className="fa-brands fa-linkedin"></i>
              </a>
            </div>

            <div className="profile-box-desc">
              Over the past year and a half with us, she has cultivated
              extensive expertise in corporate governance and compliance. Her
              responsibilities have encompassed a wide range of tasks, including
              company and LLP incorporation, managing post-incorporation
              compliances, overseeing rights issues, ensuring POSH compliance,
              handling annual filings, and addressing LLP-specific regulations.
            </div>
          </div>

          <div className="profile-box">
            <img src={Picture5} alt="" />

            <div className="profile-content">
              <h4>Ekta</h4>
              <small>Senior Associate</small>
              <a
                href="https://www.linkedin.com/in/ekta-hamirani-770314284/"
                target="_blanked"
              >
                <i className="fa-brands fa-linkedin"></i>
              </a>
            </div>

            <div className="profile-box-desc">
              Ekta With over a year of experience, she has developed a deep
              understanding of company and LLP regulations, enabling her to
              offer comprehensive support in the formation and management of
              companies and LLPs. Her expertise extends to interpreting complex
              legal frameworks, simplifying regulatory requirements, and
              offering clear guidance through every step of corporate
              compliance.
            </div>
          </div>

          <div className="profile-box">
            <img src={Picture6} alt="" />
            <div className="profile-content">
              <h4>Shrushti Dave</h4>
              <small>Senior Associate</small>

              <a
                href="https://www.linkedin.com/in/shrusti-dave-74a732235/"
                target="_blanked"
              >
                <i className="fa-brands fa-linkedin"></i>
              </a>
            </div>
            <div className="profile-box-desc">
              With over a year of experience in corporate compliance, she has
              cultivated a strong skill set and developed significant expertise
              across various regulatory requirements. Her experience spans
              Annual filing, LLP compliances, public-to-private company
              conversions, and more. She remains committed to expanding her
              knowledge and skills, contributing effectively to the
              organization’s regulatory obligations.
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Team;

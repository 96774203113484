import React, { useEffect } from "react";
import { Link } from "react-router-dom"; // Import Link for internal navigation
import bankruptcyImage1 from "./healthimg/health.webp";
import bankruptcyImage2 from "./healthimg/duediligence.webp";
import secetrialaudit from "./healthimg/secetrialaudit.webp";
import companyincorporation from "./healthimg/companyincorporation.webp";
import transactionadvisory from "./healthimg/ipoadvisory.webp";
import "../bankrupt/bankrupty.css";

const Health = () => {
  useEffect(() => {
    document.title = "Services - Compliance Health Check";
  }, []);

  return (
    <>
      <div className="bankruptcy">
        <div className="bankruptcyhead">
          <div>
            <h1>Compliance Health Check up and Due Diligence</h1>
          </div>
          <br />
          <p>
            Comprehensive assessments ensuring regulatory adherence and due
            diligence excellence.
          </p>
        </div>

        <div className="bankruptcycontent">
          <div className="bankruptcycontent0">
            <img src={bankruptcyImage1} alt="Insolvency and Bankruptcy" />
            <div className="bankruptcydetail">
              <h2>Compliance Health Check-up</h2>
              <p>
                Our Compliance Health Checkup is a thorough review designed to
                assess a company’s adherence to regulatory requirements, similar
                to a due diligence process. This includes examining primary
                documents, verifying compliance status, and reviewing statutory
                records filed with the Registrar of Companies (ROC). The aim of
                the health checkup is to identify and address any potential
                compliance lapses. We provide actionable recommendations and
                strategies to rectify existing non-compliances and mitigate
                potential risks. Additionally, we offer guidance on corrective
                measures to minimize the impact of any compliance issues and
                ensure the company remains in good standing with regulatory
                authorities.
              </p>
            </div>
          </div>
          <hr />
          <div className="bankruptcycontent1">
            <img src={bankruptcyImage2} alt="Insolvency and Bankruptcy" />
            <div className="bankruptcydetail">
              <h2>Due Diligence</h2>
              <p>
                Our due diligence services encompass a thorough review of a
                company’s primary documents, compliance status, and statutory
                records filed with the Registrar of Companies (ROC). We assess
                the maintenance of required documents and evaluate potential
                penalties or fines the company may face. Additionally, we
                perform bank-specific and compliance-specific due diligence.<br/>
                JMJA assists in conducting compliance due diligence to evaluate
                the regulatory compliance of a target company and we also carry
                out the charge search report which enables the stakeholder to
                take an appropriate decision.<br/> We are empanelled with several
                banks and companies, including ICICI Bank, PNB, and Aadhar
                Finance, among others."
              </p>
            </div>
          </div>
          {/* <div className="bankruptcycontent2">
            <img
              src={bankruptcyImage2}
              alt="Insolvency Resolution and Advisory"
            />
            <div className="bankruptcydetail">
              <h2>Due Diligence & Search Reports</h2>
              <p>
                JMJA specializes in assisting companies and creditors in
                navigating the insolvency resolution process under the IBC.
                <br />
                JMJA assists in conducting compliance due diligence to evaluate
                the regulatory compliance of a target company and we also carry
                out the charge search report which enables the stakeholder to
                take an appropriate decision.
                <br />
                We provide detailed reports that highlight compliance gaps and
                recommend corrective measures to ensure regulatory adherence and
                mitigate legal and reputational risks.
              </p>
            </div>
          </div>
          <hr /> */}
        </div>
      </div>

      <div className="workdown" id="checkwork">
        <div id="workupper">Check Out More</div>
        <div className="workcontent">
          <div className="workitem">
            <img src={companyincorporation} alt="" />
            <p style={{ fontWeight: 600, color: "#103c64" }}>
              Insolvency and Bankruptcy
            </p>
            <p>
              the complexity of insolvency and bankruptcy proceedings and are
              dedicated...
            </p>
            <button>
              <Link to="/services/rbi&fema">Learn More</Link>
            </button>
          </div>
          <div className="workitem">
            <img src={transactionadvisory} alt="" />
            <p style={{ fontWeight: 600, color: "#103c64" }}>
              Transaction Advisory
            </p>
            <p>Navigating the path to public success with expert advisory.</p>
            <button>
              <Link to="/services/transaction-advisory">Learn More</Link>
            </button>
          </div>
          <div className="workitem">
            <img src={secetrialaudit} alt="" />
            <p style={{ fontWeight: 600, color: "#103c64" }}>
              Secretarial Audit
            </p>
            <p>
              Ensuring financial transparency and compliance through audits
              and...
            </p>
            <button>
              <Link to="/services/secetrial-services">Learn More</Link>
            </button>
          </div>
        </div>
        <button id="morework1">
          <Link to="/services">More Services</Link>
        </button>
      </div>
    </>
  );
};

export default Health;

import React, { useEffect } from "react";
import { Link } from "react-router-dom"; // Import Link for internal navigation
import bankruptcyImage1 from "./sebiimg/sebiadvisory.webp";
import drafting from "./sebiimg/drafting.webp";
import transactionadvisory from "./sebiimg/startupadvisory.webp";
import bankruptcy from "./sebiimg/businessman_5337297.webp";
import "../bankrupt/bankrupty.css";

const Sebi = () => {
  useEffect(() => {
    document.title = "Services - SEBI Intermediaries";
  }, []);
  return (
    <>
      <div className="bankruptcy">
        <div className="bankruptcyhead">
          <div>
            <h1>SEBI Intermediaries</h1>
          </div>
          <br />
          <p>
            Our team of seasoned legal professionals is adept at providing
            thorough compliance services, with a specialized focus on
            Alternative Investment Funds (AIF), Listing Obligations and
            Disclosure Requirements (LODR), Prohibition of Insider Trading, and
            Substantial Acquisition and Takeovers.<br/><br/> Our support extends beyond
            consultancy and advisory, encompassing all aspects of regulatory
            compliance filings to ensure your operations align with SEBI’s
            stringent standards. We are committed to guiding companies and funds
            through the complexities of SEBI regulations, helping them navigate
            and meet all necessary requirements effectively.
          </p>
        </div>

        <div className="bankruptcycontent">
          <div className="bankruptcycontent0">
            <img src={bankruptcyImage1} alt="Insolvency and Bankruptcy" />
            <div className="bankruptcydetail">
              <h2>Compliance and Regulatory Advisory:</h2>
              <p>
                We understand the importance of stringent adherence to SEBI
                regulations to ensure the integrity and transparency of
                financial markets in India. Our team of experienced legal
                professionals specializes in providing comprehensive compliance
                services, particularly in the areas of Alternative Investment
                Funds (AIF) and Listing Obligations and Disclosure Requirements
                (LODR).
                <br />
                <br />
                We assist companies and funds not only in consultancy and
                advisory but also by way of regulatory compliance filing,
                audits, due diligence to ensure that all regulatory requirements
                are met with.
              </p>
            </div>
          </div>
          <hr />
          {/* <div className="bankruptcycontent1">
            <img
              src={bankruptcyImage2}
              alt="Insolvency Resolution and Advisory"
            />
            <div className="bankruptcydetail">
              <h2>Due Diligence & Search Reports</h2>
              <p>
                <b>Policy Drafting and Internal Audits:</b> <br />
                We help in preparing and reviewing compliance policies and
                procedures, conducting internal audits, Client Audits, and
                maintaining accurate records to meet reporting obligations.
                <br /> <br />
                <b>Fund Structuring and Documentation:</b>
                We provide guidance in preparing fund documentation, such as
                private placement memorandums (PPMs), offer documents, and
                investor/contributions agreements, ensuring clear communication
                of investment objectives, terms, risks etc.
              </p>
            </div>
          </div> */}
          <hr />
        </div>
      </div>

      <div className="workdown" id="checkwork">
        <div id="workupper">Check Out More</div>
        <div className="workcontent">
          <div className="workitem">
            <img src={bankruptcy} alt="" />
            <p style={{ fontWeight: 600, color: "#103c64" }}>
              Insolvency and Bankruptcy
            </p>
            <p>
              the complexity of insolvency and bankruptcy proceedings and are
              dedicated...
            </p>
            <button>
              <Link to="/services/bankrupt">Learn More</Link>
            </button>
          </div>
          <div className="workitem">
            <img src={transactionadvisory} alt="" />
            <p style={{ fontWeight: 600, color: "#103c64" }}>
              Transaction Advisory
            </p>
            <p>Navigating the path to public success with expert advisory.</p>
            <button>
              <Link to="/services/transaction-advisory">Learn More</Link>
            </button>
          </div>
          <div className="workitem">
            <img src={drafting} alt="" />
            <p style={{ fontWeight: 600, color: "#103c64" }}>Drafting</p>
            <p>
              Precise and comprehensive drafting solutions for your business
              needs.
            </p>
            <button>
              <Link to="/services/drafting">Learn More</Link>
            </button>
          </div>
        </div>
        <button id="morework1">
          <Link to="/services">More Services</Link>
        </button>
      </div>
    </>
  );
};

export default Sebi;

import React from "react";
import "./disclaimer.css";
const Disclaimer = () => {
  return (
    <>
      <div className="disclamerpage">
        <div className="disclamerpagehead">
          <h1>Disclaimer</h1>
        </div>
        <div className="disclamerpagecontent">
          JMJA and Associates LLP (JMJA) is a firm of Practising Company
          Secretaries registered with Institute of Company Secretaries of India
          (ICSI) and as per the rules of ICSI, JMJA is not permitted to
          advertise or solicit client. Accordingly, this website has been
          designed only for the purposes of dissemination of information;
          information which is otherwise available on the internet, various
          public platforms, and social media. JMJA & Associates LLP (JMJA) is
          not responsible for any reliance that a reader places on such
          information and shall not be liable for any loss or damage caused due
          to any inaccuracy in or exclusion of any information, or its
          interpretation thereof. The reader is advised to confirm the veracity
          of the same from independent and expert sources.<br/><br/> This website is not
          an attempt to advertise or solicit clients, the links provided on this
          website are to facilitate access basic information about JMJA and to
          share various thoughts of the leadership team of JMJA and assignments
          undertaken by JMJA. The content herein or on such links should not be
          construed as a legal reference or professional advice. Readers are
          advised not to act on any information contained herein or on the links
          and should refer to professionals and experts in their respective
          jurisdictions for further information and to determine its impact.<br/><br/>
          JMJA may use cookies on its website to improve its usability. This
          helps us in providing a good user experience and to also helps in
          improving our website. By continuing to use our website without
          changing your privacy settings, you agree to use our cookies.
        </div>
      </div>
    </>
  );
};

export default Disclaimer;

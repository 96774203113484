import React, { useEffect } from "react";
import "./contact.css";
import twitter from '../footer/footerimg/twitter.webp'
const Contact = () => {
  useEffect(() => {
    document.title = "Contact";
  }, []);

  return (
    <div>
      <div className="contact">
        <div className="contacthead"><h1>Contact Us</h1></div>

        <div className="contactmenu">
          <div className="contactcontent">
            <div className="contactcontainer">
              <div className="contactdetails">
                <h3>Contact Details</h3>
                <p>
                  Email :{" "}
                  <a href="https://mail.google.com/mail/u/0/?fs=1&tf=cm&source=mailto&to=info@jmja.in" target="_blanked">
                    info@jmja.in
                  </a>
                </p>
                <p>Phone: 022-40109730</p>

                <br />
                <hr />
              </div>
              <div className="contactadress">
                <h3>Office Address</h3>
                <p>
                  <b>Head Office :</b> 102, Accord Commercial Complex, Near
                  Goregaon Rly. Station, Goregaon (East), Mumbai-400063
                  <br />
                  <br />
                </p>
                <br />
                <hr />
              </div>
              <div className="contactsocial">
                <h3>Follow Us on Social Media</h3>
                <a
                  href="https://www.linkedin.com/company/jmjallp/"
                  target="_blanked"
                >
                  <i className="fa-brands fa-linkedin"></i>
                </a>
                <a href="https://www.instagram.com/jmjallp/" target="_blanked">
                  <i className="fa-brands fa-instagram"></i>
                </a>
                <a href="https://x.com/JMJALLP" target="_blanked">
                <a href="https://x.com/jmjallp" target="_blank">
             <img src={twitter} style={{height: "20px", paddingRight: '4px'}} alt="twitter"/>
              </a>
                </a>
              </div>
            </div>
          </div>
          <div className="contactform">
            <div className="contactformhead">
              <h2>Reach Out to Us!</h2>
              <p>
                Fill out the form below with your requirements and we will reach
                out to you shortly!
              </p>
            </div>

            <form
              id="contact_form"
              action="https://api.web3forms.com/submit"
              method="POST"
            >
              <div className="formdata">
                <input
                  type="hidden"
                  name="access_key"
                  value="3d1551eb-ffa3-4da1-b859-e9f2af153adc"
                />
                <div className="firstname">
                  <label htmlFor="first_name">First Name :</label>
                  <input
                    type="text"
                    name="first_name"
                    id="first_name"
                    required
                  />
                </div>
                <div className="lastname">
                  <label htmlFor="last_name">Last Name :</label>
                  <input type="text" name="last_name" id="last_name" required />
                </div>
                <div className="phone">
                  <label htmlFor="phone">Phone :</label>
                  <input type="tel" name="phone" id="phone" required />
                </div>
                <div className="email">
                  <label htmlFor="email">Email :</label>
                  <input type="email" name="email" id="email" required />
                </div>
                <div className="message">
                  <label htmlFor="message">Your Message :</label>
                  <textarea
                    name="message"
                    id="message"
                    cols="30"
                    rows="5"
                    required
                  ></textarea>
                </div>
              </div>
              <div className="contactformbtn">
                <button type="submit">Send Enquiry</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="map-container">
        <div className="maphead"><h1>Reach Us Here</h1></div>
        <iframe
          id="contact-map"
          title="Google Maps location of JMJA & Associates LLP"
          referrerPolicy="no-referrer-when-downgrade"
          aria-label="Google Maps location of JMJA & Associates LLP"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15074.742390433858!2d72.8505556!3d19.1652349!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b6eed05ed7e5%3A0x53b3cae1fd29d4a2!2sJMJA%20%26%20Associates%20LLP%2C%20Practising%20Company%20Secretaries%20Mumbai!5e0!3m2!1sen!2sin!4v1722260013579!5m2!1sen!2sin"
          loading="lazy"
        ></iframe>
      </div>
    </div>
  );
};

export default Contact;

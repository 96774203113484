import React, { useEffect } from "react";
import { Link } from "react-router-dom"; // Import Link for internal navigation
import bankruptcyImage1 from "./secetrialServicesimg/secetrial-services.webp";
import companyincorporation from "./secetrialServicesimg/companyincorporation.webp";
import bankruptcy from "./secetrialServicesimg/businessman_5337297.webp";
import drafting from "./secetrialServicesimg/drafting.webp";
import "../bankrupt/bankrupty.css";

const SecetrialServices = () => {
  useEffect(() => {
    document.title = "Services - Secretarial Services";
  }, []);
  return (
    <>
      <div className="bankruptcy">
        <div className="bankruptcyhead">
          <div>
            <h1>Secretarial Services</h1>
          </div>
          <br />
        </div>

        <div className="bankruptcycontent">
          <div className="bankruptcycontent0">
            <img src={bankruptcyImage1} alt="Insolvency and Bankruptcy" />
            <div className="bankruptcydetail">
              <h2>Secretarial Services</h2>
              <p>
                Setting up of a Business in India – Incorporation of a Company,
                LLP by Indian or any foreign entity in India or setting up of
                Branch Office, Liaison office in India by any foreign entity or
                Setting up of an entity in GIFT City.
                <br /> 
                Capital Raising – Consultancy and compliance relating to IPO,
                FPO, Bonus Issue and allotment of shares/debentures under
                Private Placement, Rights Issue, Issuance of shares under ESOP,
                Sweat Equity etc.
                <br />
                Transaction and Compliance – Increase in Capital of the Company,
                Shifting in Registered Office, application for compounding of
                offences, amendment to charter documents etc.
                <br />
                We provide comprehensive support for preparing and updating
                minutes of meetings and assist in organizing and conducting both
                Board and General Meetings. Our services ensure that all
                procedural requirements are met and that the meetings are
                conducted efficiently and in compliance with relevant
                regulations and Secretarial Standards.
              </p>
            </div>
          </div>
          <hr />
        </div>
        <hr />
      </div>

      <div className="workdown" id="checkwork">
        <div id="workupper">Check Out More</div>    
        <div className="workcontent">
          <div class="workitem">
            <img src={bankruptcy} alt="" />
            <p style={{ fontWeight: 600, color: "#103c64" }}>
              Insolvency and Bankruptcy
            </p>
            <p>
              the complexity of insolvency and bankruptcy proceedings and are...
            </p>
            <button>
              <a href="/services/bankrupt"> Learn More</a>
            </button>
          </div>
          <div class="workitem">
            <img src={companyincorporation} alt="" />
            <p style={{ fontWeight: 600, color: "#103c64" }}>Compliance Health Check & Due Diligence</p>
            <p>
              We conduct Bank Specific, Compliance Specific and other Due
              Diligence...
            </p>
            <button>
              <a href="/services/health"> Learn More</a>
            </button>
          </div>
          <div className="workitem">
            <img src={drafting} alt="" />
            <p style={{ fontWeight: 600, color: "#103c64" }}>Drafting</p>
            <p>
              Precise and comprehensive drafting solutions for your business
              needs.
            </p>
            <button>
              <Link to="/services/drafting">Learn More</Link>
            </button>
          </div>
        </div>
        <button id="morework1">
          <Link to="/services">More Services</Link>
        </button>
      </div>
    </>
  );
};

export default SecetrialServices;

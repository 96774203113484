import React, { useEffect } from "react";
import { Link } from "react-router-dom"; // Import Link for internal navigation
import bankruptcyImage1 from "./poshimg/poshconsultancy.webp";
import bankruptcyImage2 from "./poshimg/poshpolicy.webp";
import bankruptcyImage3 from "./poshimg/poshaudits.webp";
import bankruptcyImage4 from "./poshimg/poshtrainings.webp";
import duedelligence from "./poshimg/companyincorporation.webp";
import bankruptcy from "./poshimg/businessman_5337297.webp";
import secetrialaudit from "./poshimg/secetrialaudit.webp";
import "../bankrupt/bankrupty.css";

const Posh = () => {
  useEffect(() => {
    document.title = "Services - Prevention of Sexual Harassment (POSH)";
  }, []);
  return (
    <>
      <div className="bankruptcy">
        <div className="bankruptcyhead">
          <div>
            <h1>Prevention of Sexual Harassment (POSH)</h1>
          </div>
          <br />
        </div>

        <div className="bankruptcycontent">
          <div className="bankruptcycontent0">
            <img src={bankruptcyImage1} alt="Insolvency and Bankruptcy" />
            <div className="bankruptcydetail">
              <h2>POSH Consultancy</h2>
              <p>
                At JMJA, we are dedicated to ensuring a safe and respectful
                workplace environment for all. We specialize in providing
                comprehensive compliance solutions for the Prevention of Sexual
                Harassment (POSH) in the workplace. <br />
                <br />
                Our services include policy development, awareness training,
                complaint redressal mechanisms, and ongoing compliance audits.
                With a deep understanding of the legal and ethical aspects of
                POSH, we empower organizations across various industries to
                cultivate a culture of respect and inclusivity. <br />
                <br />
                Trust us to help your organization meet its legal obligations,
                protect its employees, and foster a harassment-free workplace
                environment.
              </p>
            </div>
          </div>
          <hr />
          <div className="bankruptcycontent1">
            <img
              src={bankruptcyImage2}
              alt="Insolvency Resolution and Advisory"
            />
            <div className="bankruptcydetail">
              <h2>POSH Policy Development</h2>
              <p>
                We specialize in helping organizations develop robust and
                legally compliant POSH policies tailored to their unique needs.
                Our team conducts a thorough assessment of your organization’s
                structure, culture, and industry-specific requirements to ensure
                the policy aligns with your values and goals.
                <br />
                <br /> We provide expert guidance in drafting policies that
                address the prevention, prohibition, and redressal of sexual
                harassment at the workplace, in line with the Sexual Harassment
                of Women at Workplace (Prevention, Prohibition, and Redressal)
                Act, 2013.
              </p>
            </div>
          </div>
          <hr />
          <div className="bankruptcycontent2">
            <img
              src={bankruptcyImage3}
              alt="Legal Representation and Litigation Support"
            />
            <div className="bankruptcydetail">
              <h2>POSH Compliance Audits</h2>
              <p>
                We conduct comprehensive audits of your organization’s policies,
                practices, and processes to assess compliance with POSH
                regulations. Our team identifies gaps, provides recommendations
                for improvement, and assists in implementing corrective
                measures. <br />
                <br />
                We help you establish mechanisms for regular monitoring and
                evaluation to ensure ongoing compliance and effectiveness of
                your POSH framework.
              </p>
            </div>
          </div>
        </div>
        <hr />
        <div className="bankruptcycontent3">
          <img
            src={bankruptcyImage4}
            alt="Legal Representation and Litigation Support"
          />
          <div className="bankruptcydetail">
            <h2>POSH Training</h2>
            <p>
              POSH training is essential for any organization that wants to
              create a safe and respectful workplace for all employees as well
              as a Compliance of law of land. <br />
              <br /> Our training programs teach employees how to identify and
              prevent sexual harassment, and how to respond if they experience
              it.
            </p>
          </div>
        </div>
        <hr />
        {/* <div class="poshdown">
          <div class="trainingvariety">
            <h2>
              We offer a variety of training options to fit your needs,
              including:
            </h2>
            <li>
              <b>In-person training: </b>Our experienced trainers will come to
              your workplace to deliver a customized training program.
            </li>
            <li>
              {" "}
              <b>Online training:</b> Our online training programs are available
              24/7 and can be accessed from anywhere.
            </li>
            <li>
              <b>Hybrid training: </b>We can combine in-person and online
              training to create a program that meets your specific needs.
            </li>
            <li>
              Our training programs are compliant with the Sexual Harassment of
              Women at Workplace (Prevention, Prohibition and Redressal) Act,
              2013. We also offer a variety of other training programs to help
              your organization create a positive and productive work
              environment.
            </li>
          </div>
          <div class="trainigbenefits">
            <h2>Benefits of POSH training:</h2>
            <li>Prevents sexual harassment in the workplace.</li>
            <li>Protects employees from legal liability.</li>
            <li>Meets legal compliance requirements.</li>
            <li>Improves employee morale and productivity.</li>
            <li>Creates a positive and respectful work environment.</li>
          </div>
        </div>
        <hr /> */}
      </div>

      <div className="workdown" id="checkwork">
        <div id="workupper">Check Out More</div>
        <div className="workcontent">
          <div class="workitem">
            <img src={bankruptcy} alt="" />
            <p style={{ fontWeight: 600, color: "#103c64" }}>
              Insolvency and Bankruptcy
            </p>
            <p>
              the complexity of insolvency and bankruptcy proceedings and are...
            </p>
            <button>
              <a href="/services/bankrupt"> Learn More</a>
            </button>
          </div>
          <div className="workitem">
            <img src={duedelligence} alt="" />
            <p style={{ fontWeight: 600, color: "#103c64" }}>Compliance Health Check & Due Diligence</p>
            <p>
              We conduct Bank Specific, Compliance Specific and other Due
              Diligence...
            </p>
            <button>
              <Link to="/services/health">Learn More</Link>
            </button>
          </div>
          <div className="workitem">
            <img src={secetrialaudit} alt="" />
            <p style={{ fontWeight: 600, color: "#103c64" }}>
              Secretarial Audit
            </p>
            <p>
              Ensuring financial transparency and compliance through audits
              and...
            </p>
            <button>
              <Link to="/services/secetrial-services">Learn More</Link>
            </button>
          </div>
        </div>
        <button id="morework1">
          <Link to="/services">More Services</Link>
        </button>
      </div>
    </>
  );
};

export default Posh;

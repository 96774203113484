import React, { useEffect } from "react";
import { Link } from "react-router-dom"; // Import Link for internal navigation
import bankruptcyImage1 from "./giftimg/giftimg.webp";
import rbi from "./giftimg/businessman_5337297.webp";
import duedelligence from "./giftimg/startupadvisory.webp";
import secetrialaudit from "./giftimg/secetrialaudit.webp";
import "../bankrupt/bankrupty.css";

const Gift = () => {
  useEffect(() => {
    document.title = "Services - GIFT CITY–FORMATION & COMPLIANCE";
  }, []);

  return (
    <>
      <div className="bankruptcy">
        <div className="bankruptcyhead">
          <div>
            <h1>GIFT City – Formation & Compliance   </h1>
          </div>
          <br />
        </div>

        <div className="bankruptcycontent">
          <div className="bankruptcycontent0">
            <img src={bankruptcyImage1} alt="Insolvency and Bankruptcy" />
            <div className="bankruptcydetail">
              <h2>GIFT CITY–FORMATION & COMPLIANCE</h2>
              <p>
                GIFT City, or Gujarat International Finance Tec-City, is a
                special economic zone located in the Indian state of Gujarat. It
                is designed to serve as a global financial and technology hub
                with state-of-the-art infrastructure and regulatory benefits
                aimed at attracting international businesses.
                <br />
                We assist the promoters from consulting for setting up of the
                Company at GIFT CITY to its day-to-day compliances being at
                GIFT.
              </p>
            </div>
          </div>
          <hr />
        </div>
        <hr />
      </div>

      <div className="workdown" id="checkwork">
        <div id="workupper">Check Out More</div>
        <div className="workcontent">
          <div class="workitem">
            <img src={rbi} alt="" />
            <p style={{ fontWeight: 600, color: "#103c64" }}>RBI & FEMA</p>
            <p>we help businesses navigate the regulatory landscape and... .</p>
            <button>
              <a href="/services/rbi&fema"> Learn More</a>
            </button>
          </div>
          <div class="workitem">
            <img src={duedelligence} alt="" />
            <p style={{ fontWeight: 600, color: "#103c64" }}>Compliance Health Check & Due Diligence</p>
            <p>
              We conduct Bank Specific, Compliance Specific and other Due
              Diligence...
            </p>
            <button>
              <a href="/services/health"> Learn More</a>
            </button>
          </div>
          <div class="workitem">
            <img src={secetrialaudit} alt="" />
            <p style={{ fontWeight: 600, color: "#103c64" }}>
              Secretarial Audit
            </p>
            <p>
              Ensuring financial transparency and compliance through audits and
              certifications...
            </p>
            <button>
              <a href="/services/secetrial-services"> Learn More</a>
            </button>
          </div>
        </div>
        <button id="morework1">
          <Link to="/services">More Services</Link>
        </button>
      </div>
    </>
  );
};

export default Gift;

import React, { useEffect } from "react";
import { Link } from "react-router-dom"; // Import Link for internal navigation
import bankruptcyImage1 from "./rbi&femaimg/rbi&fema.webp";
import secetrialaudit from "./rbi&femaimg/secetrialaudit.webp";
import rbifema from "./rbi&femaimg/companyincorporation.webp";
import startupadvisory from "./rbi&femaimg/startupadvisory.webp";
import "../bankrupt/bankrupty.css";

const Rbifema = () => {
  useEffect(() => {
    document.title = "Services - RBI & FEMA";
  }, []);
  return (
    <>
      <div className="bankruptcy">
        <div className="bankruptcyhead">
          <div>
            <h1>RBI & FEMA</h1>
          </div>
          <br />
          <p>
          With a proven track record in managing the complexities of RBI and FEMA compliance, our dedicated team excels in facilitating seamless and secure cross-border transactions for both businesses and individuals. Our extensive experience covers all facets of RBI and FEMA regulations, including capital account transactions, foreign investments in India, and adherence to regulatory guidelines. We have successfully assisted companies with FCGPR, FCTRS, ECB, FLA, APR, DI, and other relevant filings.
<br/>
<p style={{marginTop: "10px"}}>

We assist the clients in compounding of offence if the need arises. 
</p>

          </p>
        </div>

        <div className="bankruptcycontent">
          <div className="bankruptcycontent0">
            <img src={bankruptcyImage1} alt="Insolvency and Bankruptcy" />
            <div className="bankruptcydetail">
              <h2>RBI and FEMA Advisory</h2>
              <p>
                With a proven track record in navigating the intricacies of RBI
                and FEMA compliances, we have a dedicated team in facilitating
                seamless and secure cross-border transactions for businesses and
                individuals.
                <br />
                <br />
                We have extensive experience encompasses all aspects of RBI and
                FEMA compliance, including capital account transactions, foreign
                investments in India, and adherence to regulatory guidelines. We
                have assisted companies in FCGPR, FCTRS, ECB, FLA, APR, DI and
                other applicable filings for companies.
              </p>
            </div>
          </div>
          <hr />
          {/* <div className="bankruptcycontent1">
            <img
              src={bankruptcyImage2}
              alt="Insolvency Resolution and Advisory"
            />
            <div className="bankruptcydetail">
              <h2>Due Diligence & Search Reports</h2>
              <p>
                JMJA assists in conducting compliance due diligence to evaluate
                the regulatory compliance of a target company and we also carry
                out the charge search report which enables the stakeholder to
                take an appropriate decision.
                <br /> <br />
                We provide detailed reports that highlight compliance gaps and
                recommend corrective measures to ensure regulatory adherence and
                mitigate legal and reputational risks.
              </p>
            </div>
          </div> */}
          {/* <hr /> */}
        </div>

        {/* <div class="rbifemadown">
          <h2>Regulatory Approvals and Reporting</h2>
          <p>
            We streamline the process of obtaining necessary regulatory
            approvals from RBI, such as the Automatic Route as well as Approval
            Route and Downstream Investment (DI) Filing. Our team assists in
            preparing and filing the required forms, reports, and declarations,
            ensuring accuracy and adherence to timelines. We help you comply
            with reporting obligations to regulatory authorities, including
            filing of annual performance reports, KYC (Know Your Customer)
            norms, and periodic returns.
          </p>
          <p>
            <b>Liaison with RBI and Authorities:</b>
          </p>
          <p>
            JMJA acts as a liaison between your business and regulatory
            authorities, representing your interests and ensuring smooth
            communication. We facilitate interactions with RBI and other
            regulatory bodies, assisting in obtaining clarifications, resolving
            queries, and addressing compliance-related issues.
          </p>
          <p>
            <b>Issuance of Convertible Notes and other Capital Instruments:</b>
          </p>
          <p>
            We assist in Compliance as well as consultancy relating to issuance
            of Convertible Notes (CN) as approved by RBI for Startup Companies,
            issuance of ESOPs to Foreign Nationals and so on.
          </p>
        </div>
        <hr /> */}
      </div>

      <div className="workdown" id="checkwork">
        <div id="workupper">Check Out More</div>
        <div className="workcontent">
          <div class="workitem">
            <img src={startupadvisory} alt="" />
            <p style={{ fontWeight: 600, color: "#103c64" }}>
              Insolvency and Bankruptcy
            </p>
            <p>
              the complexity of insolvency and bankruptcy proceedings and are...
            </p>
            <button>
              <a href="/services/bankrupt"> Learn More</a>
            </button>
          </div>
          <div class="workitem">
            <img src={rbifema} alt="" />
            <p style={{ fontWeight: 600, color: "#103c64" }}>Compliance Health Check & Due Diligence</p>
            <p>
              We conduct Bank Specific, Compliance Specific and other Due
              Diligence...
            </p>
            <button>
              <a href="/services/health"> Learn More</a>
            </button>
          </div>
          <div class="workitem">
            <img src={secetrialaudit} alt="" />
            <p style={{ fontWeight: 600, color: "#103c64" }}>
              Secretarial Audit
            </p>
            <p>
              Ensuring financial transparency and compliance through audits and
              certifications...
            </p>
            <button>
              <a href="/services/secetrial-services"> Learn More</a>
            </button>
          </div>
        </div>
        <button id="morework1">
          <Link to="/services">More Services</Link>
        </button>
      </div>
    </>
  );
};

export default Rbifema;

import React, {useEffect} from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./gallery.css";
import img1 from "./galleryimg/img1.webp";
import img2 from "./galleryimg/img2.webp";
import img3 from "./galleryimg/img3.webp";
import img4 from "./galleryimg/img4.webp";
import img5 from "./galleryimg/img5.webp";
import img6 from "./galleryimg/img6.webp";
import img7 from "./galleryimg/img7.webp";
import img8 from "./galleryimg/img8.webp";
import img9 from "./galleryimg/img9.webp";
import img10 from "./galleryimg/img10.webp";
import img11 from "./galleryimg/img11.webp";
import img12 from "./galleryimg/img12.webp";
import img13 from "./galleryimg/img13.webp";
import img14 from "./galleryimg/img14.webp";
import img15 from "./galleryimg/img15.webp";
import img16 from "./galleryimg/img16.webp";
import img17 from "./galleryimg/img17.webp";
import img18 from "./galleryimg/img18.webp";
import img19 from "./galleryimg/img19.webp";
import img20 from "./galleryimg/img20.webp";
import img21 from "./galleryimg/img21.webp";
import img22 from "./galleryimg/img22.webp";
import img23 from "./galleryimg/img23.webp";

const sliderImages = {

  slider1: [
    { src: img1, alt: "img 1" },
    { src: img2, alt: "img 2" },
    { src: img3, alt: "img 3" },
    { src: img4, alt: "img 4" },
    { src: img5, alt: "img 5" },
  ],
  slider2: [
    { src: img6, alt: "img 6" },
    { src: img7, alt: "img 7" },
    { src: img8, alt: "img 8" },
    { src: img9, alt: "img 9" },
    { src: img10, alt: "img 10" },
    { src: img21, alt: "img 21" },
  ],
  slider3: [
    { src: img11, alt: "img 11" },
    { src: img12, alt: "img 12" },
    { src: img13, alt: "img 13" },
    { src: img14, alt: "img 14" },
    { src: img15, alt: "img 15" },
    { src: img22, alt: "img 22" },
  ],
  slider4: [
    { src: img16, alt: "img 16" },
    { src: img17, alt: "img 17" },
    { src: img18, alt: "img 18" },
    { src: img19, alt: "img 19" },
    { src: img20, alt: "img 20" },
    { src: img23, alt: "img 23" },
  ],
  mobileSlider: [
    { src: img1, alt: "img 1" },
    { src: img2, alt: "img 2" },
    { src: img3, alt: "img 3" },
    { src: img4, alt: "img 4" },
    { src: img5, alt: "img 5" },
    { src: img6, alt: "img 6" },
    { src: img7, alt: "img 7" },
    { src: img8, alt: "img 8" },
    { src: img9, alt: "img 9" },
    { src: img10, alt: "img 10" },
    { src: img11, alt: "img 11" },
    { src: img12, alt: "img 12" },
    { src: img13, alt: "img 13" },
    { src: img14, alt: "img 14" },
    { src: img15, alt: "img 15" },
    { src: img16, alt: "img 16" },
    { src: img17, alt: "img 17" },
    { src: img18, alt: "img 18" },
    { src: img19, alt: "img 19" },
    { src: img20, alt: "img 20" },
    { src: img21, alt: "img 21" },
    { src: img22, alt: "img 22" },
    { src: img23, alt: "img 23" },
  ],
};

const PartnerSlider = ({ direction, images, vertical = true }) => {
  const settings = {
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    arrows: false,
    dots: false,
    pauseOnHover: false,
    rtl: direction === "left",
    vertical: vertical,
    verticalSwiping: vertical,
    responsive: [
      {
        breakpoint: 680, // Adjusts for screens 690px or less
        settings: {
          vertical: false,
          verticalSwiping: false,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <Slider {...settings} className="customer-logos">
      {images.map((img, index) => (
        <div className="slide" key={index}>
          <img src={img.src} alt={img.alt} />
        </div>
      ))}
    </Slider>
  );
};

const MobileSlider = ({ images }) => {
  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    arrows: false,
    dots: false,
    pauseOnHover: false,
  };

  return (
    <Slider {...settings} className="mobile-slider">
      {images.map((img, index) => (
        <div className="slide" key={index}>
          <img style={{border: '2px solid black'}} src={img.src} alt={img.alt} />
        </div>
      ))}
    </Slider>
  );
};

const Gallery = () => {
  useEffect(() => { 
    document.title = "FUNdamentals";
  
  }, []);
  return (
    <>
      <div className="gallery-header">
        <h1>FUN in fundamentals</h1>
      </div>
      <div className="gallery">
        <div className="gallery-content">
          <p>
            When we think of the word 'fundamentals', it often brings to mind
            the basic principles or core skills that form the foundation of any
            discipline. However, it's crucial to understand that the energy and
            attitude we bring to the table matter infinitely more than mere
            aptitude. Skills can be honed over a period of time, but values are
            what truly set us apart. In our team, we prioritize a culture of
            respect, empathy, compassion, and authenticity, which not only
            shapes our ethos but also drives how we approach learning and
            growth.
            <br/><br/>
          Incorporating fun into the fundamentals is about finding the right balance—where core principles remain intact, but the path to mastery is exciting and inspiring. After all, when we're having fun, we’re more likely to stay committed and see things through with passion and energy. At the end of the day, we're not just a team; we're a collective of dedicated, passionate, and buzzing minds on a mission to create something extraordinary—JMJA.

</p>
        </div>
        <div className="image-border" id="border1">
          <span></span>
        </div>
        <div className="gallery-image">
          <div className="gallery-container1">
            <div id="first" className="slider-container">
              <div className="container" style={{ border: "5px solid black" }}>
                <PartnerSlider
                  direction="right"
                  images={sliderImages.slider1}
                />
              </div>
            </div>
            <br />
            <div id="second" className="slider-container">
              <div className="container" style={{ border: "5px solid black" }}>
                <PartnerSlider direction="left" images={sliderImages.slider2} />
              </div>
            </div>
          </div>
          <div className="gallery-container2">
            <div id="third" className="slider-container">
              <div className="container" style={{ border: "5px solid black" }}>
                <PartnerSlider
                  direction="right"
                  images={sliderImages.slider3}
                />
              </div>
            </div>
            <br />
            <div id="forth" className="slider-container">
              <div className="container" style={{ border: "5px solid black" }}>
                <PartnerSlider direction="left" images={sliderImages.slider4} />
              </div>
            </div>
          </div>
        </div>
        <div className="image-border" id="border2">
          <span></span>
        </div>
        <div className="gallery-image-mobile" >
          <MobileSlider  images={sliderImages.mobileSlider} />
        </div>
      </div>
    </>
  );
};

export default Gallery;

import React, { useState } from 'react';
// import celebrate from './celebrateimg/aniversory.png';
import './celebrate.css';
// import { FaWindowClose } from 'react-icons/fa';

const Celebrate = () => {
  // const [isVisible, setIsVisible] = useState(true);

  // const closeModal = () => {
  //   setIsVisible(false);
  // };

  return (
    <>
      {/* {isVisible && (
        <div className='modal'>
          <img src={celebrate} alt="Celebration" />
          <FaWindowClose onClick={closeModal} id='closeicon' />
        </div>
      )} */}
      <div className='celebration'>
        Celebrating 10 Years of JMJA
      </div>
    </>
  );
};

export default Celebrate;

import React, { useEffect } from "react";
import { Link } from "react-router-dom"; // Import Link for internal navigation
import bankruptcyImage1 from "./nbfcimg/nbfcimg.webp";
import transactionadvisory from "./nbfcimg/businessman_5337297.webp";
import bankruptcy from "./nbfcimg/startupadvisory.webp";
import secetrialaudit from "./nbfcimg/secetrialaudit.webp";
import "../bankrupt/bankrupty.css";

const Nbfc = () => {
  useEffect(() => {
    document.title = "Services - NBFC Compliance";
  }, []);
  return (
    <>
      <div className="bankruptcy">
        <div className="bankruptcyhead">
          <div>
            <h1>NBFC Compliance</h1>
          </div>
          <br />
        </div>

        <div className="bankruptcycontent">
          <div className="bankruptcycontent0">
            <img src={bankruptcyImage1} alt="Insolvency and Bankruptcy" />
            <div className="bankruptcydetail">
              <h2>NBFC Compliance</h2>
              <p>
                We specialize in Non-Banking Financial Companies (NBFCs) and
                offer extensive expertise in regulatory compliance, corporate
                governance, and legal issues specific to NBFCs and Core
                Investment Companies. We assist NBFCs in obtaining their
                Certificate of Registration (CoR) and handle regular filings
                with the Reserve Bank of India (RBI). Additionally, we provide
                advisory services on a range of regulatory and compliance
                matters.
              </p>
            </div>
          </div>
          <hr />
        </div>
        <hr />
      </div>

      <div className="workdown" id="checkwork">
        <div id="workupper">Check Out More</div>
        <div className="workcontent">
          <div class="workitem">
            <img src={bankruptcy} alt="" />
            <p style={{ fontWeight: 600, color: "#103c64" }}>
              Insolvency and Bankruptcy
            </p>
            <p>
              the complexity of insolvency and bankruptcy proceedings and are...
            </p>
            <button>
              <a href="/services/bankrupt"> Learn More</a>
            </button>
          </div>
          <div class="workitem">
            <img src={transactionadvisory} alt="" />
            <p style={{ fontWeight: 600, color: "#103c64" }}>
              Transaction Advisory
            </p>
            <p>Navigating the path to public success with expert advisory...</p>
            <button>
              <a href="/services/transaction-advisory"> Learn More</a>
            </button>
          </div>
          <div class="workitem">
            <img src={secetrialaudit} alt="" />
            <p style={{ fontWeight: 600, color: "#103c64" }}>
              Secretarial Audit
            </p>
            <p>
              Ensuring financial transparency and compliance through audits and
              certifications...
            </p>
            <button>
              <a href="/services/secetrial-services"> Learn More</a>
            </button>
          </div>
        </div>
        <button id="morework1">
          <Link to="/services">More Services</Link>
        </button>
      </div>
    </>
  );
};

export default Nbfc;

import React, {useEffect} from 'react';
import { Link } from 'react-router-dom'; // Import Link for internal navigation
import bankruptcyImage1 from './transactionAdvisoryimg/transactionadvisory.webp';
import companyincorporation from './transactionAdvisoryimg/companyincorporation.webp'
import bankruptcy from './transactionAdvisoryimg/businessman_5337297.webp'
import drafting from './transactionAdvisoryimg/drafting.webp'
import '../bankrupt/bankrupty.css'

const TransactionAdvisory = () => {
    useEffect(()=>{
        document.title = 'Services - Transaction Advisory';
    }, [])
    return (
        <>
            <div className="bankruptcy">
                <div className="bankruptcyhead">
                    <div>
                        <h1>

                        Transaction Advisory

                        </h1>



                    </div>
                    <br />

                </div>

                <div className="bankruptcycontent">
                    <div className="bankruptcycontent0">
                        <img src={bankruptcyImage1} alt="Insolvency and Bankruptcy" />
                        <div className="bankruptcydetail">
                            <h2>Transaction Advisory


                            </h2>
                            <p>
                            JMJA is actively involved in various transactions undertaken by the corporates and start-ups as advisors and consultants.


                                <br /><br />Our team of experienced legal professionals and financial experts is dedicated to supporting our clients in navigating complex legal transactions and achieving their business objectives.

                                <br /><br />
                                Transaction advisory covers consulting for mergers and amalgamations, private equity transactions, restructuring, data protection, revival of the company, strike off, and so on.






                            </p>
                        </div>
                    </div>
                    <hr />

                </div>
                <hr />


            </div>

            <div className="workdown" id="checkwork">
                <div id="workupper">Check Out More</div>
                <div className="workcontent">
                <div class="workitem">
                        <img src={companyincorporation} alt="" />
                        <p style={{ fontWeight: 600, color: '#103c64' }}>Compliance Health Check & Due Diligence

                        </p>
                        <p>We conduct Bank Specific, Compliance Specific and other Due Diligence...

                        </p>
                        <button><a href="/services/health"> Learn More</a></button>
                    </div>
                    <div class="workitem">
                        <img src={bankruptcy} alt="" />
                        <p style={{ fontWeight: 600, color: '#103c64' }}>Insolvency and Bankruptcy
                        </p>
                        <p>the complexity of insolvency and bankruptcy proceedings and are...</p>
                        <button><a href="/services/bankrupt"> Learn More</a></button>
                    </div>
                    
                    <div className="workitem">
                        <img src={drafting} alt="" />
                        <p style={{ fontWeight: 600, color: '#103c64' }}>Drafting</p>
                        <p>Precise and comprehensive drafting solutions for your business needs.

                        </p>
                        <button>
                            <Link to="/services/drafting">Learn More</Link>
                        </button>
                    </div>
                </div>
                <button id="morework1" >
                    <Link to="/services">More Services</Link>
                </button>
            </div>
        </>
    );
};

export default TransactionAdvisory;

import React, {useEffect} from 'react';
import { Link } from 'react-router-dom'; // Import Link for internal navigation
import bankruptcyImage1 from './virtualSecetrialServicesimg/virtualsecertary.webp';
import companyincorporation from './virtualSecetrialServicesimg/companyincorporation.webp'
import bankruptcy from './virtualSecetrialServicesimg/businessman_5337297.webp'
import drafting from './virtualSecetrialServicesimg/drafting.webp'
import '../bankrupt/bankrupty.css'

const VirtualSecetrialServices = () => {
    useEffect(()=>{
        document.title = 'Services - Virtual Company Secretary Services';
    }, [])
    return (
        <>
            <div className="bankruptcy">
                <div className="bankruptcyhead">
                    <div>
                        <h1>

                        Virtual Company Secretary Services
                        </h1>



                    </div>
                    <br />

                </div>

                <div className="bankruptcycontent">
                    <div className="bankruptcycontent0">
                        <img src={bankruptcyImage1} alt="Insolvency and Bankruptcy" />
                        <div className="bankruptcydetail">
                            <h2>Virtual Company Secretary Services



                            </h2>
                            <p>
                            We specialize in providing expert virtual Company Secretary services tailored to meet the unique needs of your business. With a team of qualified CS, we offer comprehensive support to ensure the compliance and consultancy requirement of the Company is taken care of at the right level.

                                <br />At JMJA, we prioritize confidentiality, accuracy, and timeliness, ensuring your company's legal obligations are met seamlessly. We also provide deputation services of qualified and Semi-Qualified Company Secretaries to meet the short-term requirements of the Company.


                              



                            </p>
                        </div>
                    </div>
                    <hr />

                </div>
                <hr />


            </div>

            <div className="workdown" id="checkwork">
                <div id="workupper">Check Out More</div>
                <div className="workcontent">
                <div class="workitem">
                        <img src={companyincorporation} alt="" />
                        <p style={{ fontWeight: 600, color: '#103c64' }}>Compliance Health Check & Due Diligence
                        </p>
                        <p>We conduct Bank Specific, Compliance Specific and other Due Diligence...

                        </p>
                        <button><a href="/services/health"> Learn More</a></button>
                    </div>
                    <div class="workitem">
                        <img src={bankruptcy} alt="" />
                        <p style={{ fontWeight: 600, color: '#103c64' }}>Insolvency and Bankruptcy
                        </p>
                        <p>the complexity of insolvency and bankruptcy proceedings and are...</p>
                        <button><a href="/services/bankrupt"> Learn More</a></button>
                    </div>
                   
                    <div className="workitem">
                        <img src={drafting} alt="" />
                        <p style={{ fontWeight: 600, color: '#103c64' }}>Drafting</p>
                        <p>Precise and comprehensive drafting solutions for your business needs.

                        </p>
                        <button>
                            <Link to="/services/drafting">Learn More</Link>
                        </button>
                    </div>
                </div>
                <button id="morework1" >
                    <Link to="/services">More Services</Link>
                </button>
            </div>
        </>
    );
};

export default VirtualSecetrialServices;

import React, { useEffect } from "react";
import { Link } from "react-router-dom"; // Import Link for internal navigation
import bankruptcyImage1 from "./draftimg/draftingandveeting2.webp";
import bankruptcyImage2 from "./draftimg/legaldrafting.webp";
import workitem1 from "./draftimg/companyincorporation.webp";
import workitem2 from "./draftimg/businessman_5337297.webp";
import workitem3 from "./draftimg/secetrialaudit.webp";
import "./draft.css";

const Draft = () => {
  useEffect(() => {
    document.title = "Services -Drafting";
  }, []);

  return (
    <>
      <div className="bankruptcy">
        <div className="bankruptcyhead">
          <div>
            <h1>Drafting</h1>
          </div>
          <br />
        </div>

        <div className="bankruptcycontent">
          <div className="bankruptcycontent0">
            <img src={bankruptcyImage1} alt="Insolvency and Bankruptcy" />
            <div className="bankruptcydetail">
              <h2> Drafting and Vetting of Agreements</h2>
              <p>
                With over 18 years of expertise in corporate law, compliance,
                and management, we have successfully assisted clients in
                drafting Shareholders' Agreements Share Subscription Agreement
                and representing their interests in various legal matters. Our
                services extend to providing ongoing legal drafting support for
                a diverse range of clients, from insurance brokers to digital
                marketing firms. We specialize in drafting and reviewing MoAs
                and AoAs to ensure the protection of stakeholder interests. As
                experts in corporate law compliance, we offer comprehensive
                advice on a wide range of legal issues, keeping pace with the
                evolving regulatory landscape.
              </p>
            </div>
          </div>
          <hr />
          <div className="bankruptcycontent1">
            <img
              src={bankruptcyImage2}
              alt="Insolvency Resolution and Advisory"
            />
            <div className="bankruptcydetail">
              <h2>Legal Drafting</h2>
              <p>
                Our team of accomplished legal experts specializes in crafting a
                comprehensive suite of legal documents, including contracts,
                agreements, memorandums of understanding (MOUs), and legal
                opinions. Our professionals possess a deep understanding of
                various legal domains, enabling us to provide expert guidance
                across a wide range of industries and sectors.
              </p>
            </div>
          </div>
            </div>
          <hr />
          
        <hr />
      </div>

      <div className="workdown" id="checkwork">
        <div id="workupper">Check Out More</div>
        <div className="workcontent">
          <div className="workitem">
            <img src={workitem1} alt="" />
            <p style={{ fontWeight: 600, color: "#103c64" }}>RBI & FEMA</p>
            <p>
              we help businesses navigate the regulatory landscape and ...
              guidelines.
            </p>
            <button>
              <Link to="/services/rbi&fema">Learn More</Link>
            </button>
          </div>
          <div className="workitem">
            <img src={workitem2} alt="" />
            <p style={{ fontWeight: 600, color: "#103c64" }}>Compliance Health Check & Due Diligence </p>
            <p>
              We conduct Bank Specific, Compliance Specific and other Due
              Diligence...
            </p>
            <button>
              <Link to="/services/health">Learn More</Link>
            </button>
          </div>
          <div className="workitem">
            <img src={workitem3} alt="" />
            <p style={{ fontWeight: 600, color: "#103c64" }}>
              Secretarial Audit
            </p>
            <p>
              Ensuring financial transparency and compliance through audits
              and...
            </p>
            <button>
              <Link to="/services/secetrial-services">Learn More</Link>
            </button>
          </div>
        </div>
        <button id="morework1">
          <Link to="/services">More Services</Link>
        </button>
      </div>
    </>
  );
};

export default Draft;

import React, { useEffect } from "react";
import { Link } from "react-router-dom"; // Import Link for internal navigation
import bankruptcyImage1 from "./orientationimg/orientation.webp";
import companyincorporation from "./orientationimg/companyincorporation.webp";
import startupadvisory from "./orientationimg/startupadvisory.webp";
import secetrialaudit from "./orientationimg/secetrialaudit.webp";
import "../bankrupt/bankrupty.css";

const Gift = () => {
  useEffect(() => {
    document.title = "Services - Orientation Programme";
  }, []);
  return (
    <>
      <div className="bankruptcy">
        <div className="bankruptcyhead">
          <div>
            <h1>Orientation Programme</h1>
          </div>
          <br />
        </div>

        <div className="bankruptcycontent">
          <div className="bankruptcycontent0">
            <img src={bankruptcyImage1} alt="Insolvency and Bankruptcy" />
            <div className="bankruptcydetail">
              <h2>Orientation Programme</h2>
              <p>
                We support and educate both Foreign and Indian Directors in
                understanding Indian laws and regulations, including their
                respective liabilities. We also conduct educational sessions for
                Directors, Key Managerial Personnel (KMPs), and employees on
                various aspects of the Companies Act, 2013, SEBI regulations,
                Prevention of Insider Trading (PIT) rules, Takeover Code,
                Environmental, Social, and Governance (ESG) criteria, and
                Corporate Social Responsibility (CSR) requirements.
              </p>
            </div>
          </div>
          <hr />
        </div>
        <hr />
      </div>

      <div className="workdown" id="checkwork">
        <div id="workupper">Check Out More</div>
        <div className="workcontent">
          <div class="workitem">
            <img src={startupadvisory} alt="" />
            <p style={{ fontWeight: 600, color: "#103c64" }}>
              Insolvency and Bankruptcy
            </p>
            <p>
              the complexity of insolvency and bankruptcy proceedings and are...
            </p>
            <button>
              <a href="/services/bankrupt"> Learn More</a>
            </button>
          </div>
          <div class="workitem">
            <img src={companyincorporation} alt="" />
            <p style={{ fontWeight: 600, color: "#103c64" }}>Compliance Health Check & Due Diligence</p>
            <p>
              We conduct Bank Specific, Compliance Specific and other Due
              Diligence...
            </p>
            <button>
              <a href="/services/health"> Learn More</a>
            </button>
          </div>
          <div class="workitem">
            <img src={secetrialaudit} alt="" />
            <p style={{ fontWeight: 600, color: "#103c64" }}>
              Secretarial Audit
            </p>
            <p>
              Ensuring financial transparency and compliance through audits and
              certifications...
            </p>
            <button>
              <a href="/services/secetrial-services"> Learn More</a>
            </button>
          </div>
        </div>
        <button id="morework1">
          <Link to="/services">More Services</Link>
        </button>
      </div>
    </>
  );
};

export default Gift;

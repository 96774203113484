import React, { useEffect } from 'react'
import {Link} from 'react-router-dom'
import drafting from './servicesimg/drafting.webp'
import bankruptcy from './servicesimg/bankrupty.webp'
import gift from './servicesimg/gift.webp'
import nbfc from './servicesimg/nbfc.webp'
import health from './servicesimg/helath.webp'
import orentation from './servicesimg/orientation.webp'
import sebi from './servicesimg/sebi.webp'
import rbi from './servicesimg/rbi.webp'
import posh from './servicesimg/posh_1.webp'
import secetrialservices from './servicesimg/secetrialservices.webp'
import transactionadvisory from './servicesimg/transactionadvisory.webp'
import virtualservicces from './servicesimg/virtualservicces.webp'

import './services.css'
const Services = () => {
useEffect(()=>{
    document.title = 'Services';
}, [])



  return (
    <>
       <div id='servicemid' className="mid">
        <div className="midcontent">
          <p>What we can do?
          </p>
          <p>Our Expertise </p>
        </div>
      </div>

       <div className="services">
        <div className="serviceshead">
            <h1>Services</h1>
            
        </div>
        <div className="servicescontent">
            <div className="service">
                <div className="serviceimg">

                    <img src={drafting} alt=""/>
                    
                    <div className="servicecontent">
                        
                        <h3>Drafting</h3>
                        <p>We provide high quality drafting solutions tailored to your specific needs</p>
                        <button><Link to="/services/drafting">Read More</Link></button>
                    </div>
                </div>
            </div>
            <div className="service" >
                <div className="serviceimg">

                    <img src={bankruptcy} alt=""/>
                    
                    <div className="servicecontent">
                        
                        <h3>Insolvency and Bankruptcy</h3>
                        <p>the complexity of
                insolvency and bankruptcy proceedings and are dedicated to providing comprehensive solutions to our
                clients.</p>
                        <button><Link to="/services/bankrupt">Read More</Link></button>
                    </div>
                </div>
            </div>
            {/* <div className="service">
                <div className="serviceimg">

                    <img src={duedelligence} alt=""/>
                    
                    <div className="servicecontent">
                        
                        <h3> Due Diligence</h3>
                        <p>We conduct Bank Specific, Compliance Specific and other Due Diligence services.</p>
                        <button><Link to="/services/duedelligence">Read More</Link></button>
                    </div>
                </div>
            </div> */}
            <div className="service" >
                <div className="serviceimg">

                    <img src={gift} alt=""/>
                    
                    <div className="servicecontent">
                        
                        <h3>GIFT CITY–FORMATION & COMPLIANCE</h3>
                        <p>GIFT offers a unique
                            opportunity currently as it has a vision to become a leading global financial & technology hub.</p>
                        <button><Link to="/services/gift">Read More</Link></button>
                    </div>
                </div>
            </div>
            <div className="service">
                <div className="serviceimg">

                    <img src={health} alt=""/>
                    
                    <div className="servicecontent">
                        
                        <h3>Compliance Health Check</h3>
                        <p>Comprehensive assessments ensuring regulatory adherence and due diligence excellence.</p>
                        <button><Link to="/services/health">Read More</Link></button>
                    </div>
                </div>
            </div>
            <div className="service">
                <div className="serviceimg">

                    <img src={nbfc} alt=""/>
                    
                    <div className="servicecontent">
                        
                        <h3>NBFC Compliance</h3>
                        <p>We are specializing in NBFC and bring in the extensive
                            expertise in regulatory compliance, corporate governance</p>
                        <button><Link to="/services/nbfc">Read More</Link></button>
                    </div>
                </div>
            </div>
            <div className="service">
                <div className="serviceimg">

                    <img src={orentation} alt=""/>
                    
                    <div className="servicecontent">
                        
                        <h3>Orientation Programme</h3>
                        <p>We assist and educate Foreign Directors as well as Indian Directors in understanding the
                            applicable laws and...</p>
                        <button><Link to="/services/orientation">Read More</Link></button>
                    </div>
                </div>
            </div>
            <div className="service">
                <div className="serviceimg">

                    <img src={secetrialservices} alt=""/>
                    
                    <div className="servicecontent">
                        
                        <h3>Secretarial Services</h3>
                        <p>Setting up of a Business in India – Incorporation of a Company, LLP by Indian or any foreign
                            entity in India or...</p>
                        <button><Link to="/services/secetrial-services">Read More</Link></button>
                    </div>
                </div>
            </div>


   


            <div className="service">
                <div className="serviceimg">

                    <img src={rbi} alt=""/>
                    
                    <div className="servicecontent">
                        
                        <h3>RBI & FEMA </h3>
                        <p> we help businesses navigate the regulatory landscape and
                            ensure compliance with RBI and FEMA guidelines.</p>
                        <button><Link to="/services/rbi&fema">Read More</Link></button>
                    </div>
                </div>
            </div>
            <div className="service">
                <div className="serviceimg">

                    <img src={sebi} alt=""/>
                    
                    <div className="servicecontent">
                        
                        <h3>SEBI</h3>
                        <p>We help the Companies for SEBI-regulated Registered Alternative Investment Funds
                            (AIF) and Portfolio Management Services</p>
                        <button><Link to="/services/sebi">Read More</Link></button>
                    </div>
                </div>
            </div>
         

            <div className="service">
                <div className="serviceimg">

                    <img src={transactionadvisory} alt=""/>
                    
                    <div className="servicecontent">
                        
                        <h3>Transaction Advisory</h3>
                        <p>JMJA is actively involved in various transactions undertaken by the corporates and...</p>
                        <button><Link to="/services/transaction-advisory">Read More</Link></button>
                    </div>
                </div>
            </div>


            <div className="service">
                <div className="serviceimg">

                    <img src={posh} alt=""/>
                    
                    <div className="servicecontent">
                        
                        <h3>Prevention of Sexual Harassment</h3>
                        <p>We understand the importance of fostering a safe and respectful work environment, and...</p>
                        <button><Link to="/services/posh">Read More</Link></button>
                    </div>
                </div>
            </div>

            <div className="service">
                <div className="serviceimg">

                    <img src={virtualservicces} alt=""/>
                    
                    <div className="servicecontent">
                        
                        <h3>Virtual Company Secretary Services</h3>
                        <p>We specialize in providing expert virtual Company Secretary services tailored to meet the unique
                            needs of your business. With...</p>
                        <button><Link to="/services/virtual-secetrial-services">Read More</Link></button>
                    </div>
                </div>
            </div>
            
        </div>

    </div>
    </>
  )
}

export default Services


import React, { useEffect } from "react";
import { Link } from "react-router-dom"; // Import Link for internal navigation
import bankruptcyImage1 from "./bankruptimg/bankrupt.webp";
import bankruptcyImage3 from "./bankruptimg/litigationsupport.webp";
import secetrialaudit from "./bankruptimg/secetrialaudit.webp";
import companyincorporation from "./bankruptimg/companyincorporation.webp";
import startupadvisory from "./bankruptimg/startupadvisory.webp";
import "./bankrupty.css";

const Bankrupt = () => {
  useEffect(() => {
    document.title = "Services - Insolvency and Bankruptcy";
  }, []);

  return (
    <>
      <div className="bankruptcy">
        <div className="bankruptcyhead">
          <div>
            <h1>Insolvency and Bankruptcy Code</h1>
          </div>
          <br />
          <p>
            Services related to the Insolvency and Bankruptcy Code IBC. We
            understand the complex nature of insolvency and bankruptcy
            proceedings and are dedicated to providing comprehensive solutions
            to our clients.
          </p>
        </div>

        <div className="bankruptcycontent">
          <div className="bankruptcycontent0">
            <img src={bankruptcyImage1} alt="Insolvency and Bankruptcy" />
            <div className="bankruptcydetail">
              <h2>Insolvency and Bankruptcy IBC</h2>
              <p>
                We specialize in assisting companies and insolvency
                professionals (IPs) with the Corporate Insolvency Resolution
                Process (CIRP) under the Insolvency and Bankruptcy Code, 2016.
                Our services cover all aspects of the CIRP, including drafting
                of resolution plan. drafting letters, publishing newspaper
                notices, organizing Board and Committee of Creditors (CoC)
                meetings, filing claims with IPs, and preparing and submitting
                documents to NCLT, IBBI, and CoC. Additionally, we represent
                banks in CoC meetings as well. <br />
                Also, we assist the companies in voluntary winding up.
              </p>
            </div>
          </div>
          <hr />
          {/* <div className="bankruptcycontent1">
            <img
              src={bankruptcyImage2}
              alt="Insolvency Resolution and Advisory"
            />
            <div className="bankruptcydetail">
              <h2>Insolvency Resolution and Advisory</h2>
              <p>
                JMJA specializes in assisting companies and creditors in
                navigating the insolvency resolution process under the IBC.
                <br />
                Our experts provide comprehensive advisory services, including
                financial restructuring, debt recovery, and negotiation with
                stakeholders.
                <br />
                We assist in the formulation and implementation of resolution
                plans, ensuring compliance with the legal requirements of the
                IBC.
              </p>
            </div>
          </div>
          <hr /> */}
          <div className="bankruptcycontent1">
            <img
              src={bankruptcyImage3}
              alt="Legal Representation and Litigation Support"
            />
            <div className="bankruptcydetail">
              <h2>Legal Representation and Litigation Support</h2>
              <p>
                We offer legal representation and litigation support for clients
                engaged in insolvency and bankruptcy proceedings. Our team
                assists with drafting and filing essential legal documents,
                represents clients before the National Company Law Tribunal
                (NCLT), and advocates for their interests in court. We provide
                strategic advice and guidance throughout the litigation process,
                striving to secure the best possible outcome for our clients.
              </p>
            </div>
          </div>
        </div>
        <hr />

        {/* <div className="bankruptcydown">
          <h2>Other Secretarial Support in Cases of IBC</h2>
          <p>
            We take care of conducting the meeting of Committees of Creditors
            CoC, its compliances, sending notices to the Company for IBC,
            submission of Claims, assisting in newspaper advertisement and the
            gamut of services linked with the Insolvency and Bankruptcy Code.
          </p>
        </div>
        <hr /> */}
      </div>

      <div className="workdown" id="checkwork">
        <div id="workupper">Check Out More</div>
        <div className="workcontent">
          <div className="workitem">
            <img src={companyincorporation} alt="" />
            <p style={{ fontWeight: 600, color: "#103c64" }}>RBI & FEMA</p>
            <p>
              we help businesses navigate the regulatory landscape and ...
              guidelines.
            </p>
            <button>
              <Link to="/services/rbi&fema">Learn More</Link>
            </button>
          </div>
          <div className="workitem">
            <img src={startupadvisory} alt="" />
            <p style={{ fontWeight: 600, color: "#103c64" }}>
              Compliance Health Check & Due Diligence
            </p>
            <p>
              We conduct Bank Specific, Compliance Specific and other Due
              Diligence...
            </p>
            <button>
              <Link to="/services/health">Learn More</Link>
            </button>
          </div>
          <div className="workitem">
            <img src={secetrialaudit} alt="" />
            <p style={{ fontWeight: 600, color: "#103c64" }}>
              Secretarial Audit
            </p>
            <p>
              Ensuring financial transparency and compliance through audits
              and...
            </p>
            <button>
              <Link to="/services/secetrial-services">Learn More</Link>
            </button>
          </div>
        </div>
        <button id="morework1">
          <Link to="/services">More Services</Link>
        </button>
      </div>
    </>
  );
};

export default Bankrupt;

import React, { useEffect } from "react";
import "./about.css";
import vision from "./aboutimg/vision.webp";
import jigar from "./aboutimg/Jigar Shah.webp";
import mansi from "./aboutimg/Mansi.webp";
import anupriya from "./aboutimg/Anupriya Saxena.webp";
const About = () => {
  useEffect(() => {
    document.title = "Know JMJA ";
  }, []);

  return (
    <>
      {/* Existing content unchanged */}
      <div id="aboutmid" className="mid">
        <div className="midcontent">
          <p>Who are we?</p>
          <p>Know JMJA</p>
        </div>
      </div>

      <div className="aboutus">
        <div className="abouthead">About Us</div>
        <div className="aboutcontent">
          <div className="aboutjmja">
            JMJA & Associates LLP (JMJA) is one of the most trusted names in the
            field of Company Secretary Services, providing expert guidance and
            solutions to clients across pan India. With more than a decade of
            the experience and a team of highly qualified professionals, we are
            committed to delivering exceptional services that meet the diverse
            needs of our clients. At JMJA, we understand that navigating the
            complex legal and regulatory landscape can be overwhelming for
            businesses. That's why our team of dedicated Company Secretaries is
            here to assist you every step of the way. We pride ourselves on
            being a one-stop solution for all your Company Secretary needs,
            ensuring compliance, transparency, and smooth operations for your
            business. <br />
            <br /> As one of the leading Company Secretary service providers
            from Mumbai, India we have built a solid reputation for our
            professionalism, integrity, and personalized approach. Our clients
            come from various industries and sectors, including startups, family
            run companies, small and medium-sized enterprises (SMEs), to large
            corporations and conglomerates. We offer tailored solutions to each
            clients, taking into account their specific requirements and
            objectives. What sets us apart is our deep understanding of the
            local business environment and the ever-changing regulatory
            landscape. Our team stays up-to-date with the latest laws,
            regulations, and industry trends to provide accurate and timely
            advice to our clients. We believe in building long-term
            relationships with our clients, and we strive to be their trusted
            advisors, supporting their growth and success. <br />
          </div>

          <div className="vision">
            <div className="visionimg">
              <img src={vision} alt="" />
              <hr />
            </div>

            <div className="about1in">
              <h1>Vision & Mission</h1>
              <p>
                To establish ourselves as the most trusted and respected Company
                Secretaries firm, where our expertise in Corporate Governance,
                Compliance, and Strategic Advisory empowers businesses to
                thrive. We envision a future where our services set the
                benchmark for ethical practices, contributing to the success and
                sustainability of our clients' enterprises.
                <br />
                <br />
                Our mission is to provide comprehensive, precise, and corporate
                secretarial and compliance solutions that empower businesses to
                meet their legal and regulatory obligations with confidence. We
                are dedicated to building enduring relationships with our
                clients by consistently delivering excellence, upholding the
                highest ethical standards and fostering a culture of continuous
                improvement. Through our unwavering commitment to integrity and
                professionalism, we aim to be the preferred partner for
                organizations seeking to navigate the complexities of corporate
                governance with clarity and assurance.
              </p>
              <br /> <br />
            </div>
          </div>
        </div>
      </div>

      <div className="founder">
        <div className="titlebox">
          <h1>The Brains Behind The Work</h1>
          <p>Leadership Team</p>
        </div>
        <div className="foundermembers">
          <div className="profile-card">
            <div className="founderimg">
              <img src={jigar} alt="" />
              <div className="caption">
                <h3>CS Jigar Shah</h3>
                <p>Founder Partner</p>
                <div className="foundersocial">
                  <a
                    href="https://www.linkedin.com/in/fcsjigarshah/"
                    target="_blanked"
                  >
                    <i className="fa-brands fa-linkedin"></i>
                  </a>

                  <a href="https://x.com/FCSJigarShah" target="_blanked">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      version="1.1"
                      id="Layer_1"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      style={{ enableBackground: "new 0 0 24 24" }}
                      xmlSpace="preserve"
                    >
                      <path d="M14.095479,10.316482L22.286354,1h-1.940718l-7.115352,8.087682L7.551414,1H1l8.589488,12.231093L1,23h1.940717  l7.509372-8.542861L16.448587,23H23L14.095479,10.316482z M11.436522,13.338465l-0.871624-1.218704l-6.924311-9.68815h2.981339  l5.58978,7.82155l0.867949,1.218704l7.26506,10.166271h-2.981339L11.436522,13.338465z" />
                    </svg>
                  </a>
                </div>
              </div>
              <div className="aboutfounder">
                <p>
                  CS Jigar Shah is a Fellow Member of the Institute of Company
                  Secretaries of India (ICSI) and a Law graduate from Mumbai
                  University. With nearly 2 decades of Industry experience, he
                  has worked with various Conglomerates and Industry leaders.
                  <br />
                  <br /> Renowned for his in-depth expertise in the Companies
                  Act, he is actively participating in knowledge sharing
                  activities as an Advisor, Panellist and a Speaker at various
                  organisation and forums on a wide array of topics, including
                  Corporate Laws, ESG, CSR, and Prohibition of Insider Trading,
                  Digital Branding for professionals and so on.
                </p>
              </div>
            </div>
          </div>

          <div className="profile-card">
            <div className="founderimg">
              <img src={mansi} alt="" />
              <div className="caption">
                <h3>CS Mansi Damania </h3>
                <p>Founder Partner</p>
                <div className="foundersocial">
                  <a
                    href="https://www.linkedin.com/in/csmansi/"
                    target="_blanked"
                  >
                    <i className="fa-brands fa-linkedin"></i>
                  </a>

                  <a href="https://x.com/mansidamania" target="_blanked">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      version="1.1"
                      id="Layer_1"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      style={{ enableBackground: "new 0 0 24 24" }}
                      xmlSpace="preserve"
                    >
                      <path d="M14.095479,10.316482L22.286354,1h-1.940718l-7.115352,8.087682L7.551414,1H1l8.589488,12.231093L1,23h1.940717  l7.509372-8.542861L16.448587,23H23L14.095479,10.316482z M11.436522,13.338465l-0.871624-1.218704l-6.924311-9.68815h2.981339  l5.58978,7.82155l0.867949,1.218704l7.26506,10.166271h-2.981339L11.436522,13.338465z" />
                    </svg>
                  </a>
                </div>
              </div>
              <div className="aboutfounder">
                <p>
                  CS Mansi Damania is a Fellow Member of the Institute of
                  Company Secretaries of India (ICSI) and a graduate of Mumbai
                  University Law School, has established a distinguished career
                  in Corporate Law. Since founding Mansi Damania and Associates
                  in 2008, she has been providing expert counsel and
                  comprehensive services to clients across a wide range of
                  corporate matters.
                  <br />
                  <br /> Her expertise extends to advising on complex
                  secretarial issues, conducting mandatory audits and due
                  diligence procedures, and navigating the intricate compliance
                  landscape of listed companies. She has managed various
                  projects such as Migration to the Main Board, Reclassification
                  of Promoters etc. along with various Secretarial work such as
                  Secretarial Audit, working as Scrutinizer for PSU Banks and
                  managing Due Diligence etc.
                </p>
              </div>
            </div>
          </div>

          <div className="profile-card">
            <div className="founderimg">
              <img src={anupriya} alt="" />
              <div className="caption">
                <h3>CS Anupriya Saxena</h3>
                <p>Managing Partner</p>
                <div className="foundersocial">
                  <a
                    href="https://www.linkedin.com/in/csanupriya/"
                    target="_blanked"
                  >
                    <i className="fa-brands fa-linkedin"></i>
                  </a>

                  <a href="https://x.com/Anupriyasays" target="_blanked">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      version="1.1"
                      id="Layer_1"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      style={{ enableBackground: "new 0 0 24 24" }}
                      xmlSpace="preserve"
                    >
                      <path d="M14.095479,10.316482L22.286354,1h-1.940718l-7.115352,8.087682L7.551414,1H1l8.589488,12.231093L1,23h1.940717  l7.509372-8.542861L16.448587,23H23L14.095479,10.316482z M11.436522,13.338465l-0.871624-1.218704l-6.924311-9.68815h2.981339  l5.58978,7.82155l0.867949,1.218704l7.26506,10.166271h-2.981339L11.436522,13.338465z" />
                    </svg>
                  </a>
                </div>
              </div>
              <div className="aboutfounder">
                <p>
                  CS Anupriya Saxena is a Fellow Member of the Institute of
                  Company Secretaries of India (ICSI). She is also holding an
                  executive degree from the prestigious Indian Institute of
                  Management Calcutta (IIM-C).
                  <br />
                  <br /> Her areas of specialization encompass a diverse range
                  of corporate transactions, including foreign and domestic
                  equity and debt funding, start-up advisory services, and
                  related transactional matters. In addition, she is a seasoned
                  professional in managing the compliance requirements of
                  Alternative Investment Funds (AIFs) and Portfolio management
                  Scheme (PMS). Her expertise extends to providing POSH
                  consultancy and compliance services, and she serves as a
                  valued external member on the POSH committees of various
                  companies.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;

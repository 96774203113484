import React from 'react'
import './notfound.css'
const NotFound = () => {
  return (
      <>
       <div className='notfound' >
        <div className='notfoundnum'>
            404
      </div>

        <div className='notfoundcontent'>
            <h2>
Page Not Found
            </h2>
            <p>
We think you just want to a page non-existing page.
            </p><br/>
            <p>
Please Navigate back to other tabs and services.
            </p>
        </div>
            
        </div>
    </>
  )
}

export default NotFound;
